import Head from "next/head";
import React from "react";
import { Login } from "../../components/organism/Login";
import RestrictedRoute from "../../withRestrictedRoute";
const SignIn = () => {
  return (
    <div>
      <Head>
        <title>side bizz-営業代行やフルコミ代理店の副業情報を掲載</title>
      </Head>
      <Login />
    </div>
  );
};

export default RestrictedRoute(SignIn);
